import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BannerBig from "../components/banner-big";
import Section from "../components/section";
import ImageColumns from "../components/image-columns";
import Image from "../components/image";
import AdvTitle from '../components/advTitle';
import LightboxContainer from "../components/lightbox-container";
import ImagesWithTextContainer from '../components/images-with-text-container';

// Owl's react component is not suited for server side render, so we lazy-load it only in runtime, not when building.
const OwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false })

export const segments = graphql`
  query IndexQuery
  {
    allSegmentsJson {
      edges
      {
        node
        {
          colSize
          className
          imageName
          imageAlt
          title
          subtitle
        }
      }
    },
    allClientsJson {
      edges
      {
        node
        {
          colSize
          className
          imageName
          imagePath {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          text
          link
        }
      }
    },
    allHelpItemsJson {
      edges {
          node {
              colSize
              className
              imageName
              imageAlt
              title
              description
          }
      }
    }
  }
`;

const IndexPage = ({ intl, data }) => {
  return <Layout>
    <SEO title={intl.formatMessage({id: "Index"})} description=""/>
    
    <BannerBig 
      className="banner banner-big" 
      filename="main-header.jpg"
      title={intl.formatMessage({id: "Your best ally to promote and finance good businesses"})}
      subtitle={intl.formatMessage({id: "Our technologies, businesses and investment vehicles at the service of your organization"})} 
      text={intl.formatMessage({id: "With our help, build your business development program, your innovation ecosystem, your investment club or your crowdfunding platform"})}
    />

    <Section title={intl.formatMessage({id: "Build your own online entrepreneurship platform"})}>
      <div className="container business-up">
          <div className="row">
              <div className="col-md-6 align-self-center">
                  <Image filename="products-business-up-laptop.png" />
                  <div className="free-trial-seal">
                      <div className="free-trial-text">
                          {intl.formatMessage({id: "Try it"})}
                          <span>{intl.formatMessage({id: "Free"})}</span>
                          {intl.formatMessage({id: "for"})}
                      </div>
                      <div className="free-trial-period">
                          <span>7</span>
                          {intl.formatMessage({id: "days"})}
                      </div>
                  </div>
              </div>
              <div className="col-md-6 business-up-features">
                  <AdvTitle title={intl.formatMessage({id: "Business Up"})} />
                  <ul>
                      <li>{intl.formatMessage({id: "Own web"})}</li>
                      <li>{intl.formatMessage({id: "Usage of your own domain"})}</li>
                      <li>{intl.formatMessage({id: "Brand Look&Feel"})}</li>
                      <li>{intl.formatMessage({id: "Editable pages"})}</li>
                      <li>{intl.formatMessage({id: "Reception and filtering projects"})}</li>
                      <li>{intl.formatMessage({id: "Goldsmith® model analysis"})}</li>
                      <li>{intl.formatMessage({id: "Own diagnostic KPIs"})}</li>
                      <li>{intl.formatMessage({id: "Business document management"})}</li>
                      <li>{intl.formatMessage({id: "Online mentoring space"})}</li>
                      <li>{intl.formatMessage({id: "Business showcase"})}</li>
                      <li>{intl.formatMessage({id: "Events and community"})}</li>
                      <li>{intl.formatMessage({id: "100% autonomous administration"})}</li>
                      <li>{intl.formatMessage({id: "Administrator training and support"})}</li>
                      <li>{intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</li>
                  </ul>
                  <div className="form-trigger">
                      <p className="free-trial-trigger-text">{intl.formatMessage({id: "For 149€ per month"})}</p>
                      <a href="https://do.adventurees.com/user/create.html">{intl.formatMessage({id: "Begin"})}</a>
                  </div>
              </div>
          </div>
      </div>
    </Section>

    { /*
    <Section title={intl.formatMessage({id: "Functionalities"})} className="features">
      <FunctionalityShowcase />
    </Section> */ }

    <Section title={intl.formatMessage({id: "Do you need additional help to build your platform?"})} className="support-items">
        <ImagesWithTextContainer elements={data.allHelpItemsJson.edges} className="help-items" />
    </Section>

    <Section title={intl.formatMessage({id: "Who hires our products?"})}>
      <ImageColumns columns={data.allSegmentsJson.edges} className="services" />
    </Section>

    <Section title={intl.formatMessage({id: "Get to know our client's platforms"})}>
      <LightboxContainer columns={data.allClientsJson.edges} className="clients-platforms" />
    </Section>
    <Section>
      <OwlCarousel
        className="owl-theme"
        loop={true}
        dots={false}
        items={6}
        nav={true}
        autoplay={true}
        autoplayTimeout={2000}
      >
        <div className="client-logo"><Image filename="client-logo-1.png"/></div>
        <div className="client-logo"><Image filename="client-logo-2.png"/></div>
        <div className="client-logo"><Image filename="client-logo-3.png"/></div>
        <div className="client-logo"><Image filename="client-logo-4.png"/></div>
        <div className="client-logo"><Image filename="client-logo-5.png"/></div>
        <div className="client-logo"><Image filename="client-logo-6.png"/></div>
        <div className="client-logo"><Image filename="client-logo-7.png"/></div>
        <div className="client-logo"><Image filename="client-logo-8.png"/></div>
        <div className="client-logo"><Image filename="client-logo-9.png"/></div>
        <div className="client-logo"><Image filename="client-logo-10.png"/></div>
        <div className="client-logo"><Image filename="client-logo-11.png"/></div>
        <div className="client-logo"><Image filename="client-logo-12.png"/></div>
        <div className="client-logo"><Image filename="client-logo-13.png"/></div>
        <div className="client-logo"><Image filename="client-logo-14.png"/></div>
        <div className="client-logo"><Image filename="client-logo-15.png"/></div>
        <div className="client-logo"><Image filename="client-logo-16.png"/></div>
        <div className="client-logo"><Image filename="client-logo-17.png"/></div>
        <div className="client-logo"><Image filename="client-logo-18.png"/></div>
        <div className="client-logo"><Image filename="client-logo-19.png"/></div>
        <div className="client-logo"><Image filename="client-logo-20.png"/></div>
        <div className="client-logo"><Image filename="client-logo-21.png"/></div>
        <div className="client-logo"><Image filename="client-logo-22.png"/></div>
      </OwlCarousel>
    </Section>
  </Layout>
}

export default injectIntl(IndexPage);