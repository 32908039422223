import React from "react";
import PropTypes from 'prop-types';

import ImageWithText from "./image-with-text";
import "../styles/images-with-text.less";

const ImagesWithTextContainer = ({elements, className}) =>
{
    if (!elements) { return null; }
    return <div className={"row justify-content-center images-with-text "+className}>
        {elements.map((element, index) => {
            return <ImageWithText key={index} element={element.node} />
        })}
    </div>
}

ImagesWithTextContainer.propTypes = {
    elements: PropTypes.array.isRequired,
    className: PropTypes.string
}

export default ImagesWithTextContainer;
