import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import Image from './image';

const ImageWithText = ({intl, element}) => {

    return <div className={"image-with-text-element col-md-"+element.colSize + " " + element.className}>
        <div className="row justify-content-center">
            <div className="image-container col-md-2 col-md-offset-1">
                <Image filename={element.imageName} alt={intl.formatMessage({id: element.imageAlt})} />
            </div>
            <div className="col-md-9 col-sm-7 text-container align-self-center">
                <p className="element-title">
                    {intl.formatMessage({id: element.title})}
                </p>
                <p className="element-description" dangerouslySetInnerHTML={{__html: intl.formatMessage({id: element.description})}}>
                </p>
            </div>
        </div>
    </div>
}

export default injectIntl(ImageWithText);
